.toolbarWrapper {
  align-items: center;
  justify-content: center;

  .toolbarText {
    color: #333348;
  }

  .toolbarLogo {
    height: 32px;
    background-size: contain;
    align-self: center;
  }
}
