.registerContainer {
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;

  .registerLogo {
    height: 45px;
    background-size: contain;
    align-self: center;
    opacity: 1;
    transition: opacity .3s;
  }

  .registerLogoHidden {
    opacity: 0;
    height: 0;
  }
}

