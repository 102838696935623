*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  box-sizing: border-box;
}

html {
  word-break: break-word;
  hyphens: auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}