.btn {
  display: flex;
  gap: 8px;

  &.facebook {
    background-color: #4267b2 !important;
  }

  &.apple {
    background-color: #000000 !important;
  }
}
