.loginContainer {
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;

  .loginLogo {
    height: 55px;
    background-size: contain;
    align-self: center;
    opacity: 1;
    transition: opacity .3s;
  }

  .loginLogoHidden {
    opacity: 0;
    height: 0;
  }
}

