.iconLogo {
  height: 64px;
  background-size: contain;
  align-self: center;
}

.squareWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
